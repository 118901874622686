import { memo } from 'react'
import { Link } from 'react-router'

import Icon from './Icon'

/**
 * @typedef {object} AvatarProps
 * @property {import('../api').User} user
 * @property {import('react').CSSProperties=} style
 * @property {string=} link
 * @property {import('react').ReactNode=} children
 */

/**
 * Avatar component
 * @param {AvatarProps} props
 */
function Avatar({ user, style, link, children }) {
    return (
        <div className="Avatar" style={style}>
            <div className="msg-avatar">
                {
                    user.uname ?
                        <a href={link || `/${user.uname}/`}>
                            {user.avatar ?
                                <img src={user.avatar} alt={`${user.uname}`} />
                                : <Icon name="ei-spinner" size="m" />}
                        </a>
                        : <Icon name="ei-spinner" size="m" />
                }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <span>
                    <a href={link || `/${user.uname}/`}>
                        <span>{user.uname}</span>
                        {user.premium && (
                            <span style={{ color: 'green' }}>
                                <Icon name="ei-star" size="s" />
                            </span>
                        )}
                    </a>
                </span>
                {children}
            </div>
        </div>
    )
}

export default memo(Avatar)
